import React from 'react';
import { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles, createMuiTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Multiselect } from 'multiselect-react-dropdown';

import { URLS } from './URLS';
import { FormGroup, Container, FormHelperText } from '@material-ui/core';
import { AppNavBar } from './AppNavBar';
import DateFnsUtils from '@date-io/date-fns';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import {BuildSelect, BuildSelect2, BuildText, BuildCheckbox, BuildMultiSelect, BuildDateTimePicker} from './Inputs'
import Chip from '@material-ui/core/Chip';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    load_skeleton: {
        background: "#b0b0b0",
    },
    props_card: {
        background: '#eee',
        margin: '3px',
    },
    results: {
        /*paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),*/
        width: '100%',
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    container: {
        alignContent: 'center',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 150,
    },
    button_general: {
        margin: theme.spacing(1),
        background: '#2196f3',
        color: '#ffffff',
        '&:hover': {
            background: '#1d72d2',
        }
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    table_cell: {
        border: '1px solid',
        borderColor: theme.palette.primary.dark,
        background: theme.palette.primary.main,
        padding: '3px',
    },
    table: {
        margin: theme.spacing(1),
        border: '1px solid',
        borderSpacing: '0px',
        width: '100%',
    },
    table_3d: {
        width: '100%',
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        margin: theme.spacing(1),
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    file_input: {
        display: 'none',
    },
    bar: {
        fill: '#ff0000',
    },
    btn_group: {
        widht: '100%',
        textAlign: 'left',
        padding: '5px',
    },
    grey_placeholder: {
        background: '#eee',
        height: "100%"
    },
}));




function ClientProjectFieldsModifier({ user, top_clients, regions }) {
    const classes = useStyles();


    var preselected_type = {
        id: 999999,
        name: 'undefined',
    }
    
    const [top_client, setTopClient] = useState(null)
    const [region, setRegion] = useState(null)
    const [fields, setFields] = useState({
    })

    const [project_schema, setProjectSchema] = useState({})
    const [project_fields, setProjectFields] = useState([])

    useEffect(()=>{
        /**getting the default schema */
        fetch(URLS.aj_get_project_schema, {
            method: "POST",
        }).then(res=>res.json()).then(response => {
            console.log({aj_get_project_schema: response});
            setProjectSchema(response.fields);
            setProjectFields(Object.keys(response.fields).map((a, ind) => {
                return(
                    {id: ind, name: a}
                )
            }))
        })
    },[user, top_clients])

    useEffect(()=>{
        if(user){
            setRegion(user.region);
        }
    },[user])

    const mapToSchema = () => {
         var mapped = {}
         console.log('mapping');
         console.log(fields);
         if(Object.keys(project_schema).length != Object.keys(fields).length){
            console.log('setting to project default')
            console.log(project_schema);
            Object.keys(project_schema).forEach(f=>{
                if(!Object.keys(fields).includes(f)){
                    mapped[f] = {type: project_schema[f].type, selected: false, required: false, unique: false, unique_with: []}
                }else{
                    mapped[f] = fields[f]
                }
                
            })
            console.log({mapped: mapped});
            setFields(mapped);
         }
         
    }

    useEffect(()=>{
        console.log('fileds changed');
        mapToSchema();
    },[fields])


    useEffect(()=>{
        if (!top_client || !region) return;
        
        fetch(URLS.aj_get_top_client_prj_fields, {
            method: "POST",
            body: JSON.stringify({
                top_client_id: top_client.id,
                region_id: region.id,
            })
        }).then(res => res.json()).then((result) => {
            console.log(result);
            
                var newfields = result.schema;
                console.log(newfields)
                setFields({...fields,...newfields})
            
        },
            (error) => {
                console.log(error);
            })

    },[top_client, region])  
    


    function handleTopClientChange(val, json_name, list) {
        var elem = list.filter(e => e.id === val)[0]
        var selectedText = elem.name;
        setTopClient({id: val, name: selectedText})
 
    }


    function handleRegionChange(val, json_name, list) {
        var elem = list.filter(e => e.id === val)[0]
        var selectedText = elem.name;
        setRegion({id: val, name: selectedText})
 
    }


    function checkError() {
        return "";
    }

    function handleSubmit(e) {

        console.log(fields)

        fetch(URLS.aj_set_top_client_prj_fields, {
            method: "POST",
            body: JSON.stringify({
                top_client_id: top_client.id,
                region_id: region.id,
                prj_fields: fields,
            })
        }).then(res => res.json()).then((result) => {
            console.log(result);
        },

            (error) => {
                console.log(error);
            })

    }

    function handleSelectedChange(val, json_name){
        var newfields = fields;
        if (!json_name.includes('.')) {
            newfields[json_name].selected = val;
        } else {
            var res = json_name.split('.');
            newfields[res[0]][res[1]].selected = val;
        }
 
        setFields(newfields);
    }

    function handleRequiredChange(val, json_name){
        var newfields = fields;
        if (!json_name.includes('.')) {
            newfields[json_name].required = val;
        } else {
            var res = json_name.split('.');
            newfields[res[0]][res[1]].required = val;
        }
 
        setFields(newfields);
    }

    function handleUniqueChange(val, json_name){
        var newfields = fields;
        if (!json_name.includes('.')) {
            newfields[json_name].unique = val;
        } else {
            var res = json_name.split('.');
            newfields[res[0]][res[1]].unique = val;
        }
 
        setFields(newfields);
    }

    function handleUniqueWithChange(val, json_name){
        console.log({'multi change': json_name, 'val': val})
        var newfields = fields;
        if (!json_name.includes('.')) {
            newfields[json_name].unique_with = val;
        } else {
            var res = json_name.split('.');
            newfields[res[0]][res[1]].unique_with = val;
        }
        console.log(newfields)
        setFields(newfields);
    }


    return (
 
        <Grid container spacing={1}>
            <Grid item xl={12}>
                <Card>
                    <CardContent>
                        <BuildSelect2 json_name='region' label='REGION' list={regions} init_value={region} getError={checkError} onChange={handleRegionChange} />
                    </CardContent>
                </Card>
                <Card>
                    <CardContent>
                        <BuildSelect2 json_name='top_client' label='Client' list={top_clients} init_value={top_client} getError={checkError} onChange={handleTopClientChange} />
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xl={12}>
                <Card>
                    <CardActions>
                        <Button onClick={handleSubmit} className={classes.button_general}>SAVE!</Button>
                    </CardActions>
                    <CardContent>
                        {fields !== {} &&
                            <Table>
                                <TableHead>
                                    <TableCell>Active/ Visible</TableCell>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Type</TableCell>
                                    <TableCell>Required (can not be blank)</TableCell>
                                    <TableCell>Unique</TableCell>
                                    <TableCell>Unique With</TableCell>
                                </TableHead>
                                {Object.keys(fields).map(a => {
                                    return (
                                        <TableRow>
                                            <TableCell><BuildCheckbox json_name={a} label={''} init_value={fields[a].selected} getError={checkError} onChange={handleSelectedChange} /></TableCell>
                                            <TableCell>{a}</TableCell>
                                            <TableCell><Chip label={fields[a].type} variant="outlined" /></TableCell>
                                            <TableCell><BuildCheckbox json_name={a} label={''} init_value={fields[a].required} getError={checkError} onChange={handleRequiredChange} /></TableCell>
                                            <TableCell><BuildCheckbox json_name={a} label={''} init_value={fields[a].unique} getError={checkError} onChange={handleUniqueChange} /></TableCell>
                                            <TableCell><BuildMultiSelect json_name={a} label="" init_value={fields[a].unique_with} getError={checkError} list={project_fields} onChange={handleUniqueWithChange} /></TableCell>
                                        </TableRow>
                                    )
                                })}
                            </Table>
                        }
                    </CardContent>
                    
                </Card>
            </Grid>
        </Grid>
  
    )

}

export { ClientProjectFieldsModifier }