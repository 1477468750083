import React from 'react';
import { useState, useEffect, useRef } from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles, createMuiTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Multiselect } from 'multiselect-react-dropdown';
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction' // needed for dayClick
import $ from 'jquery';
import { URLS } from './URLS';
import { default_project } from './UTILS'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import Switch from '@material-ui/core/Switch';


import { BuildSelect, BuildSelect2, BuildText, BuildCheckbox, BuildMultiSelect, BuildDateTimePicker, BuildDateStartEndPicker, BuildFloat } from './Inputs'

import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';
import { Typography, Card, CardContent, CardActions, CardHeader } from '@material-ui/core';
import { useStyles } from '@material-ui/pickers/views/Calendar/SlideTransition';

import IconButton from '@material-ui/core/IconButton';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { AlertTitle } from '@material-ui/lab';

import CircularProgress from '@material-ui/core/CircularProgress';

var moment = require('moment');


const styles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    load_skeleton: {
        background: "#b0b0b0",
    },
    props_card: {
        background: '#eee',
        margin: '3px',
    },
    results: {
        /*paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),*/
        width: '100%',
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    container: {
        alignContent: 'center',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 150,
    },
    button_general: {
        margin: theme.spacing(1),
        background: '#2196f3',
        color: '#ffffff',
        '&:hover': {
            background: '#1d72d2',
        }
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    table_cell: {
        border: '1px solid',
        borderColor: theme.palette.primary.dark,
        background: theme.palette.primary.main,
        padding: '3px',
    },
    table: {
        margin: theme.spacing(1),
        border: '1px solid',
        borderSpacing: '0px',
        width: '100%',
    },
    table_3d: {
        width: '100%',
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        margin: theme.spacing(1),
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
      },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    file_input: {
        display: 'none',
    },
    bar: {
        fill: '#ff0000',
    },
    btn_group: {
        widht: '100%',
        textAlign: 'left',
        padding: '5px',
    },
    grey_placeholder: {
        background: '#eee',
        padding: '10px',
    },
    appBar: {
        position: 'relative',
      },
      title: {
        marginLeft: theme.spacing(2),
        flex: 1,
      },
      selected:{
          background: 'red',
      },
      orderableTH: {
        background: '#555555',
        color: '#ffffff',
        borderLeft: '1px solid #999999',
        padding: '2px',
      },
}));

const _MS_PER_DAY = 1000 * 60 * 60 * 24;
function dateDiffInDays(a, b) {
    // Discard the time and time-zone information.
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
    var days = Math.floor((utc2 - utc1) / _MS_PER_DAY);
    //console.log(days);
    return Math.abs(days);
  }

function TasksCalendar({passed_date, salary_mode, user, propagateDateToParent}){

    const calendarComponentRef = useRef()

    const [tasks, setTasks] = useState([]);
    const [selected_date, setMySelectedDate] = useState(passed_date);
    

    useEffect(()=>{
        propagateDateToParent(new Date());
    },[])
    
    useEffect(()=>{
        var date = new Date(passed_date);

        setMySelectedDate(date);
    },[passed_date])


    useEffect(()=>{
        getTasks();
    },[selected_date])

    function getTasks() {
        fetch(URLS.ajax_get_tasks_calendar,{
            method: "POST",
            body: JSON.stringify({
                user_id: user.id,
                year: selected_date.getFullYear(),
                month: selected_date.getMonth() + 1,
                project_id: null,
                salary_mode: salary_mode,
            })
        }).then(res=>res.json()).then(function(result){
            console.log(result);
            var formatted = []
            if(salary_mode){
                formatted = result.tasks.map(a=>{return {start: a.date, title: a.salary}})
            }else{
                formatted = result.tasks.map(a=>{return {start: a.date, title: a.regular}})
            }
           
            setTasks(formatted)
        })
    }


    function getPanelClass(){
        if(salary_mode == true){
            return 'panel panel-danger';
        }else{
            return 'panel panel-default';
        }
    }

    function getBodyClass(){
        if(salary_mode == true){
            return 'panel-body panel-danger';
        }else{
            return 'panel-body panel-default';
        }
    }

    function handleDateClick(arg){
        $(arg.jsEvent.target).addClass('selected');
        //console.log(arg.jsEvent.target);
        //console.log({set: Date.parse(arg.date), current: Date.parse(new Date())});
        //console.log(new Date(Date.parse(arg.date)));
        //console.log(new Date(Date.parse(new Date())));
        if(Date.parse(arg.date) > Date.parse(new Date())){
            alert("Future date");
            return 0
        }
        
        var api = calendarComponentRef.current.getApi();
        setMySelectedDate(arg.date);
        //console.log({the_date: arg.date});
        propagateDateToParent(arg.date);

        api.gotoDate(arg.date);
    }

    function handleMonthChange(info) {
        if(info.view.currentStart > new Date()){
            return 0;
        }
        setMySelectedDate(info.view.currentStart);
        propagateDateToParent(info.view.currentStart);
    }
 


    return (

        <div className={getPanelClass()}  id="tasks_calendar">
 
            <div className={getBodyClass()}>
        <FullCalendar
            defaultView="dayGridMonth"
            header={{
                left: 'prev,next',
                center: 'title',
                right: 'dayGridMonth'
            }}
            plugins={[dayGridPlugin, interactionPlugin]}
            //selectable={true}
            firstDay={1}

            events={tasks}
            dateClick={handleDateClick}
            ref = {calendarComponentRef}
            datesRender={handleMonthChange}
        />
            </div>
        </div>

    )
 
}

function TaskForm({ user, task_types, prj_id_name=null, so_sub_task_types, title, onclose}) {

    const classes = styles();

    const [selected_date, setSelectedDate] = useState(new Date());
    const [localUser, setLocalUser] = useState(user);
    const [state, setState] = useState({
        id: -1,
        command: "SUBMIT",
        open: false,
        start_dt: new Date(),
        end_dt: new Date(),
        start_time: '00:00',
        end_time: '00:00',
        task_type: {
            id: -1,
            name: "NONE"
        },
        eod: false,
        so_type: user.region.id == 7,
        project: {
            id: -1,
            name: "NONE",
        },
        comment: []
    })
    
    const [salary_mode, setSalaryMode] = useState(false);

    const [local_task_types, setLocalTaskTypes] = useState([]);

    const [current_tasks, setCurrentTasks] = useState([
        {
            id: -1,
            prj: {
                id: -1,
                name: "None",
            },
            start_time: null,
            end_time: null,
            date: null,
            type: {
                id: -1,
                name: "None",
            },
            comment: ""
        }
    ]);
    
    const [projects_list, setProjectList] = useState([]);
    const [users_list, setUserList] = useState([]);

    const [hours_add, setHoursAdd] = useState(0);

    const [errors, setErrors] = useState([]);

    const [openDialog, setOpenDialog] = useState(false); 

    const [active_task_id, setActiveTaskId] = useState(null);

    const [activeSubmit, setActiveSubmit] = useState(true);
    const [restrictions_on, setRestrioctionsOn] = useState(false);


    useEffect(()=>{
        setLocalUser(user)
    },[user]);

    useEffect(()=>{
        console.log(localUser);
        fetch(URLS.aj_get_all_users, {
            method: "POST",
            body: JSON.stringify({
                uid: localUser.id,
            })
        }).then(res => res.json()).then((result) => {
            var nusers_list = [{ id: -1, name: 'NONE' }, ...result.all_users];
            console.log(nusers_list);
            setUserList(nusers_list);
        },
        (error) => {
            console.log(error);
        })
    },[localUser])

    useEffect(()=>{
        var days_span = 3;
        if(selected_date.getDay() == 1){
            days_span = 4
        }
        if(dateDiffInDays(new Date(), selected_date) > days_span){
            if(["EDIT","COPY"].includes(state.command)){
                alert("Trying to set too old date here. Resetting!");
                handleReset();
            }
            setRestrioctionsOn(true);
        }else{
            setRestrioctionsOn(false);
        }
    },[selected_date])
    

    function triggerEOD(){
        setState({...state, eod: !state.eod});
    }

    useEffect(()=>{
        if(state.eod === true){
            //alert("trigger 00:00")
            var nstate = {...state, end_time: "00:00"};
            setState(nstate);
        }
    }, [state.eod])

    function setStart(time){
        var nstate = {...state}
        nstate.start_time = time;
        nstate.start_dt.setHours(parseInt(time.split(":")[0]), parseInt(time.split(":")[1]));
        setState(nstate);
    }

    function setEnd(time){
        var nstate = {...state}
        nstate.end_time = time;
        nstate.end_dt.setHours(parseInt(time.split(":")[0]), parseInt(time.split(":")[1]));
        setState(nstate);
    }

    function clickDialogButton(task_id){
        setActiveTaskId(task_id);
        setOpenDialog(true);
    }
    function closeDialog(){
        setOpenDialog(false)
    }
    function handleAgree(){
        setOpenDialog(false);
        handleDelete(active_task_id);
    }
  

    useEffect(()=>{
        console.log(task_types)
        if(task_types){
            setLocalTaskTypes(task_types); 
        }
    },[task_types])



    useEffect(()=>{

        var nstate = {...state};
        //console.log({state_before_ROLE_PRJ: nstate})
        //console.log({prj_id_name: prj_id_name});
        console.log({extra_role: localUser.extra_role})
        console.log({extra_role: !localUser.extra_role})
        if (localUser.role == "FM" && !localUser.extra_role && (!nstate.task_type || nstate.task_type.id == -1)) {
            console.log('setting SO')
            var filtered_task = task_types.find(x => x.name == 'SAMPLE ONLY');
            var nstate = {...state};
            nstate.task_type = filtered_task;
        }
        if(prj_id_name){
            nstate.project = prj_id_name;
        }
        setState(nstate);

        return function cleanup(){
            
            handleReset();
            onclose(null);
        }

    },[localUser.extra_role])


    const handleSwitch = () => {
        
        setSalaryMode(!salary_mode);
        handleReset();
    }

    function getAllProjects(search_string) {
        if(search_string.length < 3){
            return 0
        }
        fetch(URLS.aj_get_all_projects, {
            method: "POST",
            body: JSON.stringify({
                uid: localUser.id,
                search_string: search_string,
            })
        }).then(res => res.json()).then((result) => {
            var nprojects_list = [{ id: -1, name: 'NONE' }, ...result.all_projects];
            setProjectList(nprojects_list);
        },

            (error) => {
                //console.log(error);
            })
    }

    function handleReset() {
        //console.log('RESET')
        var nstate = {...state};
 
        nstate.id = -1;
        nstate.command = "SUBMIT";
        nstate.open = false;
        nstate.start_dt = new Date();
        nstate.end_dt = new Date();
        nstate.start_time = '00:00';
        nstate.end_time = '00:00';
        nstate.end_dt.setHours(0,0,0);
        nstate.start_dt.setHours(0,0,0);
        //end_time = null,
        nstate.task_type = {
            id: -1,
            name: "NONE"
        };
        nstate.eod = false;
        nstate.so_type = localUser.region.id == 7;
        nstate.project = {
            id: -1,
            name: "NONE",
        };
        nstate.comment = []
      
        //console.log({from_reset: nstate});
        handleNewDate(new Date());
        setState(nstate);
        
        
    }

    function handleSubmit() {
        setActiveSubmit(false);
        setErrors([]);
        var before_send = {...state};
 
        var overlap = false;
        var task = {}
        task.id = state.id;
        task.command = state.command;
        task.selected_date = state.start_dt.getFullYear() + '-' + ("0" + (state.start_dt.getMonth() + 1)).slice(-2) + '-' + ('0' + (state.start_dt.getDate())).slice(-2)
        
        task.start_time = state.start_time;
        task.end_time = state.end_time;
        task.task_id = state.task_type.id;
        task.project_id = state.project.id;
        task.hours_add = hours_add;
        task.so_sub_types_selected = state.so_sub_task_types_selected;
        task.eod = state.eod;
        task.comment_text = state.comment;

        //console.log(task);
        //console.log(current_tasks);
        

        if (!hours_add) {
            var add_start = moment(state.start_dt, "HH:mm").hours() * 60 + moment(state.start_dt, "HH:mm").minutes();
            var add_end = moment(state.end_dt, "HH:mm").hours() * 60 + moment(state.end_dt, "HH:mm").minutes();
        }


        if (add_start >= add_end && state.command != "DELETE" && !state.hours_add && !state.eod) {
            //console.log({start: add_start, end: add_end})
            overlap = true;
            var nstate = state;
            nstate.overlap = overlap;
            setErrors(['Start and End time mismatch']);
            setState(nstate);
            setActiveSubmit(true);
            return null;
        }

        if (state.eod && state.end_time != '0:0' && state.end_time != '00:00') {
            overlap = true;
            var nstate = state;
            nstate.overlap = overlap;
            setState(nstate);
            setErrors(['EOD selected but the end time is not 00:00']);
            setActiveSubmit(true);
            return null;
        }
        //console.log(state.so_type);
        if (state.command != "DELETE" && !state.so_type) {
            for (var i = 0; i < current_tasks.length; i++) {
                //this one handles editing task; we do not need to validate the edited one with itself
                if (current_tasks[i].id == state.id) {
                    continue;
                }
                var start = moment(current_tasks[i].start_time, "HH:mm").hours() * 60 + moment(current_tasks[i].start_time, "HH:mm").minutes();
                var end = moment(current_tasks[i].end_time, "HH:mm").hours() * 60 + moment(current_tasks[i].end_time, "HH:mm").minutes();

                if (((add_start >= start && add_end <= end) || add_start == start || add_end == end) && !task.eod) {
                    overlap = true;
                    var nstate = state;
                    nstate.overlap = overlap;
                    setState(nstate);
                    setErrors(['Overlapping tasks']);
                    setActiveSubmit(true);
                    return null;
                    
                }

                if (((add_start > start && add_start < end) || (add_end > start && add_end < end)) && !task.eod) {
                    overlap = true;
                    var nstate = state;
                    nstate.overlap = overlap;
                    setState(nstate);
                    setErrors(['Overlapping tasks']);
                    setActiveSubmit(true);
                    return null;
                }

                if ((add_start < start && add_end >= end && end != 0) && !task.eod) {
                    overlap = true;
                    var nstate = state;
                    nstate.overlap = overlap;
                    setState(nstate);
                    setErrors(['Overlapping tasks']);
                    setActiveSubmit(true);
                    return null;
                }
                if(add_start < end && add_end === 0){
                    overlap = true;
                    var nstate = state;
                    nstate.overlap = overlap;
                    setState(nstate);
                    setErrors(['Overlapping EOD task with regular one']);
                    setActiveSubmit(true);
                    return null;
                }
                if(add_end === 0 && end === 0){

                    overlap = true;
                    var nstate = state;
                    nstate.overlap = overlap;
                    setState(nstate);
                    setErrors(['Overlapping EOD tasks']);
                    setActiveSubmit(true);
                    return null;
                }
            }
        }
        console.log(JSON.stringify(task))
        fetch(URLS.aj_submit_task, {
            method: "POST",
            body: JSON.stringify({
                task: task,
                user_id: localUser.id,
            })
        }).then(res => res.json()).then(function (response) {
            //alert(JSON.stringify(response))
            if (response.stat == "OK") {
                overlap = false;
                var nstate = {...state};
                nstate.overlap = overlap;
                nstate.local_task_types = [{ id: -1, name: 'NONE' }, ...task_types];
                //console.log({state_after_submit: nstate})
                setState(nstate);
                handleNewDate(before_send.end_dt);
            }
            else {
                setErrors(["There are wrong inputs in your request. Please check carefully the task type and the project fields."]);
            }
            setActiveSubmit(true);
        })

    }

    function handleCopy(ind) {
        var task = current_tasks[ind];
        //console.log(task);
        var nstate = {...state};
        nstate.id = -1;
        nstate.project = { id: task.prj.id, name: task.prj.name };
        nstate.date = task.date;
        nstate.command = "COPY";
        nstate.start_time = task.start_time;
        nstate.end_time = task.end_time;
        nstate['task_type'] = { id: task.type.id, name: task.type.name };
        
        try {
            nstate.comment = JSON.parse(task.comment);
        }
        catch (err) {
            nstate.comment = task.comment;
        }
        setState(nstate);
    }

    function handleEdit(ind) {
        var task = current_tasks[ind];
        var nstate = {...state};
        nstate.id = task.id;
        nstate.project = { id: task.prj.id, name: task.prj.name };
        nstate.date = task.date;
        nstate.command = "EDIT";
        nstate.start_time = task.start_time;
        nstate.end_time = task.end_time;
        nstate.start_dt = new Date(selected_date);
        nstate.end_dt = new Date(selected_date);
        nstate.start_dt.setHours(parseInt(task.start_time.split(":")[0]), parseInt(task.start_time.split(":")[1]));
        nstate.end_dt.setHours(parseInt(task.end_time.split(":")[0]), parseInt(task.end_time.split(":")[1]));
        nstate['task_type'] = { id: task.type.id, name: task.type.name };
        //console.log({from_edit: nstate});
        
        try {
            nstate.comment = JSON.parse(task.comment);
        }
        catch (err) {
            nstate.comment = task.comment;
        }
        setState(nstate);
    }

    function handleDelete(id) {
        fetch(URLS.aj_delete_task, {
            method: "POST",
            body: JSON.stringify({
                task_id: id,
                user_id: localUser.id,
            })
        }).then(res=>res.json()).then(result=>{
            var before_send = {...state};
            handleNewDate(before_send.start_dt);
        })
    }

    function setInput(val, json_name) {
        var newstate = {...state};
        if (!json_name.includes('.')) {
            newstate[json_name] = val;
        } else {
            var res = json_name.split('.');
            newstate[res[0]][res[1]] = val;
        }
 
        setState(newstate);

    }

    function handleSelectChange(val, json_name, list) {
        var elem = list.filter(e => e.id === val)[0];
        var newstate = {...state};
        //console.log({select_ELEM: elem})
        if(elem){
            var selectedText = elem.name;
            if (!json_name.includes('.')) {
                //console.log(newstate);
                //console.log(json_name);
                //console.log(newstate[json_name]);
                newstate[json_name] = elem;
            } else {
                var res = json_name.split('.');
                newstate[res[0]][res[1]].id = val;
                newstate[res[0]][res[1]].name = selectedText;
            }
        }else{
            newstate[json_name] = {id: undefined, name: undefined}
        }
        

        newstate['unsaved'] = true;
        setState(newstate);
    }

    function handleMultiChange(val, json_name) {
        
        var newstate = {...state};
        if (!json_name.includes('.')) {
            newstate[json_name] = val;
        } else {
            var res = json_name.split('.');
            newstate[res[0]][res[1]] = val;
        }
        newstate['unsaved'] = true;
        setState(newstate);
        
        
        var arr = [...val];
        var addition = 0;
        val.forEach(a=>addition = addition+a.hour_add);
 
        setHoursAdd(addition)
    }

    function handleNewStartEndDateTime(start_val, json_name) {
        var newstate = {...state};
 
        setSelectedDate(new Date(start_val));
        //handleNewDate(new Date(start_val));
        var dstart = new Date(start_val);
        var dend = new Date(start_val);
        dstart.setHours(parseInt(state.start_time.split(":")[0]), parseInt(state.start_time.split(":")[1]));
        dend.setHours(parseInt(state.end_time.split(":")[0]), parseInt(state.end_time.split(":")[1]));
        newstate['start_dt'] = dstart;
        newstate['end_dt'] = dend;
        setState(newstate);
        handleNewDate(dstart);
    }

    function onDateInputChange(date_val){
        setSelectedDate(new Date(date_val));
    }
 

    function handleNewDate(ndate) {

        var nstate = {...state};
        console.log({'handles_new_date with state': nstate});

        nstate.start_dt = new Date(ndate);
        nstate.start_dt.setHours(parseInt(state.start_time.split(":")[0]), parseInt(state.start_time.split(":")[1]))

        nstate.end_dt = new Date(ndate);
        nstate.end_dt.setHours(parseInt(state.end_time.split(":")[0]), parseInt(state.end_time.split(":")[1]))

        setState(nstate);
        var date = ndate.getFullYear() + '-' + ("0" + (ndate.getMonth() + 1)).slice(-2) + '-' + ('0' + (ndate.getDate())).slice(-2) 
        setSelectedDate(ndate);

        fetch(URLS.aj_get_daily_tasks, {
            method: "POST",
            body: JSON.stringify({
                date: date,
                user_id: localUser.id,
            })
        }).then(res => res.json()).then(function (response) {
            var ncurrent_tasks = response.day_tasks;
            if (localUser.role == "FM") {
                ncurrent_tasks.sort((a, b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0));
            }
            setCurrentTasks(ncurrent_tasks);
        })

    }

    function checkError(){
        return ""
    }

    const asyncLoadProjects = (searchText) => {
        getAllProjects(searchText)
    } 

    return (
        <React.Fragment>
            <Grid container spacing={2}>
                {false && 
                    <Grid item xl={12} md={12}>
                        <BuildSelect2 json_name='local_user' label='User' list={users_list} init_value={localUser} getError={checkError} onChange={handleSelectChange} />    
                    </Grid>
                }
                
                <Grid item xl={6} md={6}>
                    <Card>
                        <CardContent>
                            <Typography variant='h5'>TASKS CALENDAR</Typography>
                            
                            
                            {localUser.region.id === 7 && 
                                <Switch
                                checked={salary_mode}
                                onChange={handleSwitch}
                                name="salary_mode"
                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                />
                            }
                            
                        </CardContent>
                        <CardContent>
                            <TasksCalendar passed_date={selected_date} salary_mode={salary_mode} user={localUser} propagateDateToParent={handleNewDate} />
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xl={6} md={6} spacing={2}>
                    <Grid container spacing={2}>
                    <Grid item xl={12} md={12}>
                        <Card>
                            <CardContent>
                            <Typography variant='h5'>{state.command !== "SUBMIT" && 
                                <Alert severity="error">
                                    <AlertTitle>
                                    You've entered {state.command} mode!
                                    </AlertTitle>
                                    Select the 'RESET' button in order to exit this mode once you are ready.
                                </Alert>
                            }</Typography>
                            
                            <Typography variant='h5'>{current_tasks?current_tasks.length:0} TASK(S) FOR {('0' + (selected_date.getDate())).slice(-2) + '-' + ("0" + (selected_date.getMonth() + 1)).slice(-2) + '-' + selected_date.getFullYear()}</Typography>
                            </CardContent>
                            <CardContent>
                                <div style={{overflow: 'auto', height: '250px', }} id='tasks_scroll'>
                                <Table className='table' aria-label="simple table">
                                    <TableHead>
                                        <TableCell className={classes.orderableTH}>Controls </TableCell>
                                        <TableCell className={classes.orderableTH}>Name </TableCell>
                                        <TableCell className={classes.orderableTH}>Start </TableCell>
                                        <TableCell className={classes.orderableTH}>End </TableCell>
                                        <TableCell className={classes.orderableTH}>Duration </TableCell>
                                        <TableCell className={classes.orderableTH}>Type </TableCell>
                                        <TableCell className={classes.orderableTH}>Comments </TableCell>
                                    </TableHead>
                                    <TableBody>
                                        {current_tasks.map((tt, ind) => (
                                            <TableRow>
                                                <TableCell>
                                                    <div style={{minWidth: '140px'}}>
                                                        <IconButton aria-label="delete" onClick={() => clickDialogButton(tt.id)}>
                                                            <DeleteForeverIcon fontSize="small" />
                                                        </IconButton>

                                                        {state.command == "EDIT" && state.id == tt.id &&
                                                            <IconButton aria-label="edit" onClick={() => handleEdit(ind)}>
                                                                <EditIcon fontSize="small" />
                                                            </IconButton>
                                                        }

                                                        {(state.command != "EDIT" || state.id != tt.id) &&
                                                            <IconButton aria-label="edit" onClick={() => handleEdit(ind)}>
                                                                <EditIcon fontSize="small" />
                                                            </IconButton>
                                                        }

                                                        {localUser.role != 'FM' &&
                                                            <IconButton aria-label="copy" onClick={() => handleCopy(ind)}>
                                                                <FileCopyIcon fontSize="small" />
                                                            </IconButton>
                                                        }
                                                    </div>
                                                </TableCell>
                                                <TableCell>
                                                    {tt.prj.name}
                                                </TableCell>
                                                <TableCell>
                                                    {tt.start_time}
                                                </TableCell>
                                                <TableCell>
                                                    {tt.end_time}
                                                </TableCell>
                                                <TableCell>
                                                    {tt.duration} hrs.
                                                </TableCell>
                                                <TableCell>
                                                    {tt.type.name}
                                                </TableCell>
                                                <TableCell>
                                                    {tt.comment}
                                                </TableCell>
                                            </TableRow>

                                        ))}
                                        </TableBody>
                                    </Table>
                                    <Dialog
                                        open={openDialog}
                                        onClose={closeDialog}
                                        aria-labelledby="alert-dialog-title"
                                        aria-describedby="alert-dialog-description"
                                    >
                                        <DialogTitle id="alert-dialog-title">Please confirm the action</DialogTitle>
                                        <DialogContent>
                                            <DialogContentText id="alert-dialog-description">
                                                The action you're going to execute is permanent, non-revertable and we ask you to confirm it.
                                            </DialogContentText>
                                        </DialogContent>
                                        <DialogActions>
                                            <Button onClick={handleAgree} color="primary">
                                                Confirm
                                            </Button>
                                            <Button onClick={closeDialog} color="primary" autoFocus>
                                                Forget
                                            </Button>
                                        </DialogActions>
                                    </Dialog>
                                </div>
                            </CardContent>
                        </Card>
                    </Grid>

                    

                    <Grid item xl={12} xs={12}>
                        <Typography variant="h5" style={{ marginBottom: '15px' }}>NEW TASK</Typography>
                    </Grid>

                    {salary_mode == false &&
                        <Grid item xl={12} xs={12}>
                            <BuildSelect2 json_name='project' label='Project' list={projects_list} init_value={state['project']} getError={checkError} onChange={handleSelectChange} asyncLoadList={asyncLoadProjects} />
                        </Grid>
                    }
                    <Grid item xl={12} xs={12}>
                        <BuildDateStartEndPicker 
                        init_value={selected_date} label="" json_name='' onChange={handleNewStartEndDateTime} ondatechange={onDateInputChange} future={false} start={state.start_time} end={state.end_time} setStart={setStart} setEnd={setEnd} active={!restrictions_on}/>
                        <Button onClick={triggerEOD} color={state.eod?'primary':'secondary'}>EOD
                        {state.eod === true &&
                            <span>: ON</span>
                        }
                        {state.eod === false &&
                            <span>: OFF</span>
                        }
                        </Button>
                        
                    </Grid>


                    {salary_mode == false &&
                        <Grid item xl={12} xs={12}>
                            <BuildSelect2 json_name='task_type' label='Task Type' list={local_task_types} init_value={state['task_type']} getError={checkError} onChange={handleSelectChange} />
                        </Grid>
                    }
                    {salary_mode == true &&
                        <Grid item xl={12} xs={12}>
                            <BuildSelect2 json_name='task_type' label='Task Type' list={[...local_task_types.filter(a => a.id == 38 ? a : null)]} init_value={null} getError={checkError} onChange={handleSelectChange} />
                        </Grid>
                    }
                    {localUser.role != 'FM' &&
                        <Grid item xl={12} xs={12}>
                            <BuildText json_name='comment' label="Comment" getError={checkError} onChange={setInput} init_value={state['comment']} />
                        </Grid>
                    }


                    {localUser.role == 'FM' && salary_mode == false &&
                        <Grid item xl={12} xs={12}>
                            <BuildMultiSelect json_name="comment" label="SO task subtype" init_value={state['comment']} getError={checkError} list={so_sub_task_types} onChange={handleMultiChange} />
                        </Grid>
                    }

                    {(localUser.role == 'FM' && salary_mode == false) &&
                        <Grid item xl={12} xs={12}>

                            <BuildFloat json_name='hours_add' label="Hours" getError={checkError} onChange={setHoursAdd} init_value={hours_add} />

                        </Grid>
                    }
                    <Grid item xl={12} xs={12}>
                        {activeSubmit && ((state.command == "SUBMIT" && !restrictions_on) || state.command != "SUBMIT") && 
                            <Button onClick={handleSubmit} className={classes.button_general}>{state.command}</Button>
                        }
                        {!activeSubmit && 
                            <CircularProgress />
                        }
                        <Button onClick={handleReset} className={classes.button_general}>Reset</Button>
                    </Grid>
                   
                
                    
      
                    {errors.length !== 0 &&
                        <Snackbar open={errors.length} autoHideDuration={6000} onClose={() => setErrors([])}>
                            <Alert onClose={() => setErrors([])} severity="error">
                                {errors.map((e, ind) => {
                                    return (
                                        <div>{e}</div>
                                    )
                                })}
                            </Alert>
                        </Snackbar>
                    }
                    
                </Grid>
                </Grid>
            </Grid>

            

            

        </React.Fragment>

    );
}


export {TaskForm}