import React, { memo } from 'react';
import { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles, createMuiTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Tooltip from '@material-ui/core/Tooltip';

import {URLS} from './URLS';
import { FormGroup, Container, FormHelperText } from '@material-ui/core';
import {AppNavBar} from './AppNavBar'; 
import DateFnsUtils from '@date-io/date-fns';
import {dafault_project, default_project} from './UTILS.js'
import Icon from '@material-ui/core/Icon';
import LinearProgress from '@material-ui/core/LinearProgress';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import {MuiPickersUtilsProvider,KeyboardTimePicker,KeyboardDatePicker} from '@material-ui/pickers';
import Snackbar from '@material-ui/core/Snackbar';

import clock_icon from './assets/images/add_clock.png';
import pen_icon from './assets/images/edit.png';
import info_icon from './assets/images/info.png';
import archive_icon from './assets/images/archive.png';
import unarchive_icon from './assets/images/unarchive.png';
import refresh_icon from './assets/images/refresh.png';
import subroute_icon from './assets/images/sub.png';
import del_icon  from './assets/images/trash.png';
import subscribe_icon from './assets/images/eye.png';
import unsubscribe_icon from './assets/images/eye_cross.png'
import external from './assets/images/external.png'
import $ from 'jquery';


import {BuildSelect, BuildSelect2, BuildText, BuildCheckbox, BuildMultiSelect, BuildDateTimePicker, EditableINP, BuildMultiSelectCreateable} from './Inputs'

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import log from './assets/images/log.png'
import drive from './assets/images/drive.png'
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Draggable from "react-draggable";

var moment = require('moment');





function ActionButton({name, onAction, image, helper, variant="standard"}){
    const classes = useStyles();
    const [openDialog, setOpenDialog] = useState(false); 
    function clickDialogButton(){
        setOpenDialog(true)
    }
    function closeDialog(){
        setOpenDialog(false)
    }
    function handleAgree(){
        onAction()
        setOpenDialog(false)
        
    }
    return(
        <React.Fragment>
            <Tooltip title={helper}>
                <Button className={classes.btn_circle} onClick={variant=='standard'?onAction:clickDialogButton}>
                    {!image &&
                        <span title={helper}>{name}</span>
                    }
                    {image && 
                        <img src={image}/>
                    }
                </Button>

            </Tooltip>

            <Dialog
            open={openDialog}
            onClose={closeDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            >
            <DialogTitle id="alert-dialog-title">Please confirm the action</DialogTitle>
            <DialogContent>
            <DialogContentText id="alert-dialog-description">
                The action you're going to execute is permanent, non-revertable and we ask you to confirm it.
            </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button onClick={handleAgree} color="primary">
                Confirm
            </Button>
            <Button onClick={closeDialog} color="primary" autoFocus>
                Forget
            </Button>
            </DialogActions>
        </Dialog>
      </React.Fragment>
    )
}

function ProjectActionButtons({
    project, 
    loadEditForm, 
    handleRefresh, 
    loadSubForm, 
    propagateDelete, 
    loadInfoForm,
    loadCopyForm,
    handleArchive,
    handleUnarchive,
    handleOpenExternal,
    handleAddTask,
    handleShowComments,
    handleShowSDPlus,
    user}){
 

    function clientRedirect(){
        console.log("Redirect");
        var win = window.open(project.client_link, '_blank');
        win.focus();
    }

    var subscribed = false;
    var owned = false;
    if(project.subscribers.indexOf(user.id)!=-1){
        subscribed = true;
    }
    if(project.owner.id == user.id){
        owned = true;
    }


    const openLog = () => {
        var win = window.open(project.logs_url, '_blank');
        win.focus();
    }

    const openFolder = () => {
        var win = window.open(project.folder_url, '_blank');
        win.focus();
    }

    const buttonMapper = [
        {name: 'AddTask', actionFn: handleAddTask, image: clock_icon, helper: 'Add Task for this project', shown: true},
        {name: 'Edit', actionFn: loadEditForm, image: pen_icon, helper: 'Edit this project', shown: true},
        {name: 'Info', actionFn: loadInfoForm, image: info_icon, helper: 'More Info', shown: false},
        {name: 'Archive', actionFn: handleArchive, image: archive_icon, helper: 'Archive this project', shown: project.archive=="False"},
        {name: 'Unarchive', actionFn: handleUnarchive, image: unarchive_icon, helper: 'Unarchive this project', shown: project.archive=="True"},
        {name: 'Refresh', actionFn: handleRefresh, image: refresh_icon, helper: 'Refresh this project', shown: true},
        {name: 'SubProject', actionFn: loadSubForm, image: subroute_icon, helper: 'Create sub project', shown: project.linked_to=="None"},
        {name: 'Delete', actionFn: propagateDelete, image: del_icon, helper: 'Delete this project', shown: project.tasks_count==0, variant:"dialog"},
        {name: 'Subscribe', actionFn: null, image: subscribe_icon, helper: 'Subscribe to this project', shown: !owned && !subscribed},
        {name: "Unsubscribe", actionFn: null, image: unsubscribe_icon, helper: "Unsubscribe from this project", shown: subscribed && !owned},
        {name: 'SD+', actionFn: handleShowSDPlus, image: null, helper: 'Show SD+ data', shown: project.sd_plus!="None"},
        {name: 'ClientLink', actionFn: handleOpenExternal, image: external, helper: 'Optional external link for client', shown: true},
        {name: 'Logs', actionFn: openLog, image: log, helper: 'Project Logs', shown: project.logs_url && project.logs_url != ''},
        {name: 'Docs', actionFn: openFolder, image: drive, helper: 'Docs Folder', shown: project.folder_url && project.folder_url != ''},
        {name: 'Notes', actionFn: handleShowComments, image: null, helper: 'Project Notes', shown: true},
        {name: 'Copy', actionFn: loadCopyForm, image: null, helper: 'Copy', shown: true},
    ]

    return (
            <React.Fragment>
                {buttonMapper.map((a)=>{
                    return(
                        
                            <React.Fragment>
                                {a.shown &&
                                    <ActionButton name={a.name} onAction={() => a.actionFn(project)} image={a.image} helper={a.helper} variant={a.variant}/>
                                }
                            </React.Fragment>
 
                    )
                })}
            </React.Fragment>
    )
 

}

const useStyles = makeStyles((theme) => ({
    noMaxWidth: {
      maxWidth: '80%',
      minWidth: '250px',
      marginLeft: '10%',
    },
    box: {
        display: 'table-cell',
        width: '2px !important',
        cursor: 'col-resize',
    },
    box_noDrag: {
        display: 'table-cell',
        width: '2px !important',
        //cursor: 'col-resize',
    }
  }));

const LightProjectView = memo(function({init_state, cols_order, lists, user, style, onDelete, onEdit, loadCopyForm, onAddTask, onSuccessfullUpdate, expand ,onarchive, onrefresh, onAddSub, onComments, odDetails, onShowSDPlus}){
    
    const [state, setState] = useState(init_state);

    const classes = useStyles();

    const [sbopen, setSBopen] = useState(false);
    const [client_link, setClientLink] = useState(null);
    const [stil, setStil] = useState({
        background: '#ffffff',
        color: '#333333',
    });

    const [contextpos, setContextPos] = useState({
        mouseX: null,
        mouseY: null,
    })


    useEffect(()=>{
        setClientLink(URLS.client_external+'?clid='+init_state.client.id+'&prid='+init_state.id);
        console.log(URLS.client_external+'?clid='+init_state.client.id+'&prid='+init_state.id);
    },[init_state])

    useEffect(()=>{
        console.log("new light init state")
        var nstate = {...init_state};
        nstate.edit_me = false;
        nstate.add_sub = false;
        nstate.unsaved = false;
        nstate.show_billings = false;
        nstate.show_calc = false;
        nstate.fast_add_so = 0;
        nstate.openSB = false;
        nstate.selected = false;
        nstate.expand = expand?expand:false;
        if(typeof nstate.comment == 'string'){
             
            try{
                nstate.comment = JSON.parse(nstate.comment);
            }catch(err){
                console.log(err);
                console.log(nstate.comment);
            }
             
        }
        setState(nstate);
        console.log(typeof nstate.comment);
    },[init_state])

 
 
    const num_subs = React.createRef();
 
    function clientRedirect(){
        var win = window.open(client_link, '_blank');
        win.focus();
    }

        

    function checkError(){
        return ''
    }


    function setInput(val, json_name){
        var newstate = state;
        if (!json_name.includes('.')) {
            newstate[json_name] = val;
        } else {
            var res = json_name.split('.');
            newstate[res[0]][res[1]] = val;
        }
        newstate['unsaved'] = true;
        setState(newstate);
        //handleValidate();
    }

    function handleSelectChange(val, json_name, list){
        var elem = list.filter(e => e.id === val)[0]
        var selectedText = elem.name;
        var newstate = state;
        if (!json_name.includes('.')) {
            newstate[json_name].id = val;
            newstate[json_name].name = selectedText;
        } else {
            var res = json_name.split('.');
            newstate[res[0]][res[1]].id = val;
            newstate[res[0]][res[1]].name = selectedText;
        }

        setState(newstate);
        //handleValidate();
    }

    function handleCheckboxChange(val, json_name){
        var newstate = state;
        if (!json_name.includes('.')) {
            newstate[json_name] = val;
        } else {
            var res = json_name.split('.');
            newstate[res[0]][res[1]] = val;
        }
        setState(newstate);
        //handleValidate();
    }

    function handleMultiChange(val, json_name){
        var newstate = state;
        if (!json_name.includes('.')) {
            newstate[json_name] = val;
        } else {
            var res = json_name.split('.');
            newstate[res[0]][res[1]] = val;
        }
 
        setState(newstate);
        //handleValidate();
    }

    function handleMultiCreateableChange(val, json_name){
        console.log(val);
        var newstate = state;
        if (!json_name.includes('.')) {
            newstate[json_name] = val;
        } else {
            var res = json_name.split('.');
            newstate[res[0]][res[1]] = val;
        }
 
        setState(newstate);
        //handleValidate();
    }

    function handleNewDateTime(val, json_name){
        console.log(val);
        var data = new Date(val);
        console.log(data);

        console.log(json_name);
        var newstate = state;
        var date = data.getFullYear() + "-" + (data.getMonth()+1) + "-" + data.getDate();
        var time = data.getHours()+":"+data.getMinutes();
        if (!json_name.includes('.')) {
            newstate[json_name]['date'] = date;
            newstate[json_name]['time'] = time;
        } else {
            var res = json_name.split('.');
            newstate[res[0]][res[1]]['date'] = date;
            newstate[res[0]][res[1]]['time'] = time;
        }
        setState(newstate);
        //handleValidate();
    }


    var general_class = 'bordered';
 
    if(state.linked_to!="None"){
        general_class += " sub_project to_me" /*+this.state.to*/
    }

    const handleSubmitInput = () => {
        
        var prj = {...state};
        var success = false;

        fetch(URLS.aj_create_project, {
            method: "POST",
            body: JSON.stringify({
                project: prj,
                user: user,
                force_similar: true,
                operation: 'edit',
            })
        }).then(res => res.json()).then((result) => {
            if(result.project){
                console.log(result.project);
                setState(JSON.parse(result.project));
            }else{
                alert("Hard errors while saving project")
            }

        },

        (error) => {
            console.log(error);
        })
    }

    const handleExpand = () => {
        console.log("EXPAND ME")
    }

    const closeSB = () => {
        setSBopen(false)
    }
    

    var cells = {
        'billed_on': <EditableINP input={<BuildDateTimePicker init_value={state.billed_on?state.billed_on.date +"T"+ state.billed_on.time+":00":null} label="Billed On" json_name='billed_on' onChange={handleNewDateTime}/>} show={state.billed_on?state.billed_on.date +" "+ state.billed_on.time+":00":null} pushUpdate={handleSubmitInput}/>,
        'selected': <BuildCheckbox json_name="selected" label="" init_value={state.selected} getError={checkError} onChange={handleCheckboxChange}/>,
        'sn': <EditableINP input={<BuildText json_name='sn' label="SN" getError={checkError} helper="Usually 6 digits" onChange={setInput} init_value={state['sn']}/>} show={state.sn} pushUpdate={handleSubmitInput}/> ,
        'maconomy': <EditableINP input={<BuildText json_name='maconomy' label="Maconomy" getError={checkError} helper="" onChange={setInput} init_value={state['maconomy']}/>} show={state.maconomy} pushUpdate={handleSubmitInput}/>,
        'access_pro': <EditableINP input={<BuildText json_name='access_pro' label="Access Pro" getError={checkError} helper="" onChange={setInput} init_value={state['access_pro']}/>} show={state.access_pro} pushUpdate={handleSubmitInput}/>,
        'project_name': <EditableINP input={<BuildText json_name='project_name' label="Project Name" getError={checkError} helper="" onChange={setInput} init_value={state['project_name']}/>} show={state['project_name']} pushUpdate={handleSubmitInput}/>,
        'url': <EditableINP input={<BuildText json_name='url' label="URL" getError={checkError} helper="" onChange={setInput} init_value={state['url']}/>} show={<span>edit {(state.url && state.url!='None' && state.url!='')?<a href={state.url} target="_blank">link</a>:''}</span>} pushUpdate={handleSubmitInput}/>,
        
        'client': <EditableINP input={<BuildSelect2 json_name='client' label='Client' list={lists.clients} init_value={state['client']} getError={checkError} onChange={handleSelectChange}/>} show={state.client.name} pushUpdate={handleSubmitInput}/>,
        'office': <EditableINP input={<BuildSelect2 json_name='office' label='Office' list={lists.offices} init_value={state['office']} getError={checkError} onChange={handleSelectChange}/>} show={state.office.name} pushUpdate={handleSubmitInput}/>,
        'subs': state.linked_projects? <div className="btn btn-primary small subs_num" onClick={handleExpand}>+{state.linked_projects.length}</div>: state.linked_to !== 'None' ? <span>|<br/>|---<br/>|</span> : '',
        'owner': <React.Fragment>
                <EditableINP input={<BuildSelect2 json_name='owner' label='Owner' list={lists.users} init_value={state['owner']} getError={checkError} onChange={handleSelectChange}/>} show={state.owner.name} pushUpdate={handleSubmitInput}/>
                <EditableINP input={<BuildMultiSelect json_name="subscribers" label="Subscribers" init_value={state.subscribers} getError={checkError} list={lists.users} onChange={handleMultiChange}/>} show={state.subscribers?state.subscribers.map(a=>a.name).join(', '):"None"} pushUpdate={handleSubmitInput}/>
            </React.Fragment>,
        'pm_onshore': <EditableINP input={<BuildSelect2 json_name='pm_onshore' label='Onshore PM' list={lists.pms} init_value={state['pm_onshore']} getError={checkError} onChange={handleSelectChange}/>} show={state.pm_onshore?.name} pushUpdate={handleSubmitInput}/>,
        'pm_offshore': <EditableINP input={<BuildSelect2 json_name='pm_offshore' label='Offshore PM' list={lists.pms} init_value={state['pm_offshore']} getError={checkError} onChange={handleSelectChange}/>} show={state.pm_offshore?.name} pushUpdate={handleSubmitInput}/>,
        'type': <EditableINP input={<BuildSelect2 json_name='type' label='Type' list={lists.types} init_value={state['type']} getError={checkError} onChange={handleSelectChange}/>} show={state.type.name} pushUpdate={handleSubmitInput}/>,
        'status': 
            <span style={{'background': state.status.color,
            'display': 'block',
            'padding': '2px',
            'border-radius': '10px',
            'text-align': 'center',}}>
                <EditableINP input={<BuildSelect2 json_name='status' label='Status' list={lists.statuses} init_value={state['status']} getError={checkError} onChange={handleSelectChange}/>} show={state.status.name} pushUpdate={handleSubmitInput}/>
            </span>,
        'orion': state.orion,
        'controls': <span>
            <ProjectActionButtons
                project={state}
                loadEditForm={onEdit}
                handleRefresh={onrefresh}
                loadSubForm={onAddSub}
                loadCopyForm={loadCopyForm}
                propagateDelete={onDelete}
                loadInfoForm={odDetails}
                handleArchive={onarchive}
                handleUnarchive={onarchive}
                handleAddTask={onAddTask}
                handleShowComments={onComments}
                handleShowSDPlus={onShowSDPlus}
                handleOpenExternal={clientRedirect}

                user={user} />
        </span>,
        
        'delivery':  <EditableINP 
                        input={<BuildDateTimePicker 
                                init_value={state.link_delivery.date +"T"+ state.link_delivery.time+":00"} 
                                label="Delivery" 
                                json_name='link_delivery' 
                                onChange={handleNewDateTime}/>} 
                                show={state.link_delivery.date +" "+ state.link_delivery.time+":00"} 
                                pushUpdate={handleSubmitInput}/>,
        'zone': <EditableINP input={<BuildText json_name='zone' label="zone" getError={checkError} onChange={setInput} init_value={state['zone']}/>} show={state.zone} pushUpdate={handleSubmitInput}/>,
        'last_edit': state.last_edit.date + ', '  + state.last_edit.time,
        'attention': <EditableINP input={<BuildSelect2 json_name='attention' label='Attention' list={lists.attentions} init_value={state['attention']} getError={checkError} onChange={handleSelectChange}/>} show={state.attention.name} pushUpdate={handleSubmitInput}/>,

        'sd_plus': <EditableINP input={<BuildText json_name='sd_plus' label="SD+" getError={checkError} onChange={setInput} init_value={state['sd_plus']}/>} show={state.sd_plus} pushUpdate={handleSubmitInput}/>,
        'questions': <EditableINP input={<BuildText json_name='questions' label="Questions" getError={checkError} onChange={setInput} init_value={state['questions']}/>} show={state.questions} pushUpdate={handleSubmitInput}/>,
        'eta': <EditableINP input={<BuildText json_name='eta' label="ETA" getError={checkError} onChange={setInput} init_value={state['eta']}/>} show={state.eta} pushUpdate={handleSubmitInput}/>,
        'eta_tabs': <EditableINP input={<BuildText json_name='eta_tabs' label="ETA Tabs" getError={checkError} onChange={setInput} init_value={state['eta_tabs']}/>} show={state.eta_tabs} pushUpdate={handleSubmitInput}/>,
        'eta_cj': <EditableINP input={<BuildText json_name='eta_cj' label="ETA CJ" getError={checkError} onChange={setInput} init_value={state['eta_cj']}/>} show={state.eta_cj} pushUpdate={handleSubmitInput}/>,
        'eta_dp': <EditableINP input={<BuildText json_name='eta_dp' label="ETA DP" getError={checkError} onChange={setInput} init_value={state['eta_dp']}/>} show={state.eta_dp} pushUpdate={handleSubmitInput}/>,
        
        'eta_charts': <EditableINP input={<BuildText json_name='eta_charts' label="ETA Charts " getError={checkError} onChange={setInput} init_value={state['eta_charts']}/>} show={state.eta_charts} pushUpdate={handleSubmitInput}/>,
        'eta_project': <EditableINP input={<BuildText json_name='eta_project' label="ETA Project " getError={checkError} onChange={setInput} init_value={state['eta_project']}/>} show={state.eta_project} pushUpdate={handleSubmitInput}/>,

        'eta_fw': <EditableINP input={<BuildText json_name='eta_fw' label="ETA FW " getError={checkError} onChange={setInput} init_value={state['eta_fw']}/>} show={state.eta_fw} pushUpdate={handleSubmitInput}/>,
        'eta_overlay': <EditableINP input={<BuildText json_name='eta_overlay' label="ETA Overlay " getError={checkError} onChange={setInput} init_value={state['eta_overlay']}/>} show={state.eta_overlay} pushUpdate={handleSubmitInput}/>,
        'eta_pm': <EditableINP input={<BuildText json_name='eta_pm' label="ETA PM " getError={checkError} onChange={setInput} init_value={state['eta_pm']}/>} show={state.eta_pm} pushUpdate={handleSubmitInput}/>,

        'quota_approved': <EditableINP input={<BuildCheckbox json_name="quota_approved" label="Quota Approved" init_value={state.quota_approved} getError={checkError} onChange={handleCheckboxChange}/>} show={state.quota_approved} pushUpdate={handleSubmitInput}/>,
        'complexity': state['complexity_new']?<React.Fragment>
                <EditableINP input={<BuildSelect2 json_name='complexity_new' label='Complexity' list={lists.complexities} init_value={state['complexity_new']} getError={checkError} onChange={handleSelectChange}/>} show={state.complexity_new.name} pushUpdate={handleSubmitInput}/>
                <EditableINP input={<BuildText json_name='complexity_notes' label="Complexity Notes" getError={checkError} helper="" onChange={setInput} init_value={state['complexity_notes']}/>} show={state.complexity_notes} pushUpdate={handleSubmitInput}/>
            </React.Fragment>:<span>blank</span>,
        
        'revised_eta': <EditableINP input={<BuildText json_name='revised_eta' label="Revised ETA" getError={checkError} helper="" onChange={setInput} init_value={state['revised_eta']}/>} show={state.revised_eta} pushUpdate={handleSubmitInput}/>, 
        'eta_percentages': <span>{state.eta_percentages} %</span>,
        'extra_coverage': <EditableINP input={<BuildCheckbox json_name="extra_coverage" label="Extra Coverage" init_value={state.extra_coverage} getError={checkError} onChange={handleCheckboxChange}/>} show={state.extra_coverage} pushUpdate={handleSubmitInput}/>,
        'spent_hours_admin': <span>{state.spent_hours_admin}</span>,
        'spent_hours_overlay': <span>{state.spent_hours_overlay}</span>,
        'spent_hours_qa': <span>{state.spent_hours_qa}</span>,
        'spent_hours_qd': <span>{state.spent_hours_qd}</span>,
        'spent_hours_qdqa': <span>{state.spent_hours_qdqa}</span>,
        'spent_hours_qdqa_changes': <span>{state.spent_hours_qdqa_changes}</span>,

        'spent_hours_so': <span><span className="label so_total_hours">{state.spent_hours_so}</span><br/><span className="label so_my_hours">{state.tasks_by_user?state.tasks_by_user[user.id]?state.tasks_by_user[user.id][6]?state.tasks_by_user[user.id][6]:0:0:0}</span></span>,

        'spent_hours_dp': <span>{state.spent_hours_dp}</span>,
        'spent_hours_data_validation': <span>{state.spent_hours_data_validation}</span>,
        'spent_hours_cjdp': <span>{state.spent_hours_cjdp}</span>,
        'spent_hours_cjpr': <span>{state.spent_hours_cjpr}</span>,
        'spent_hours_data_script': <span>{state.spent_hours_data_script}</span>,
        'spent_hours_tabwork': <span>{state.spent_hours_tabwork}</span>,
        'spent_hours_data_visualization': <span>{state.spent_hours_data_visualization}</span>,
        'spent_hours_charting': <span>{state.spent_hours_charting}</span>,

        'fast_add_so': <EditableINP input={<BuildText json_name='so_hours_add' label="SO Hours Add" getError={checkError} helper="" onChange={setInput} init_value={state['so_hours_add']}/>} show={state.so_hours_add} pushUpdate={handleSubmitInput}/>, 
        'general_type': <EditableINP input={<BuildSelect2 json_name='general_type' label='General Type' list={lists.general_types} init_value={state['general_type']} getError={checkError} onChange={handleSelectChange}/>} show={state.general_type.name} pushUpdate={handleSubmitInput}/>,
        //'comment': <div dangerouslySetInnerHTML={{ __html: state.comment.text?state.comment.text.slice(0,150)+"...":"" }} title={state.comment.text?state.comment.text:""} style={{maxWidth: '300px', fontSize: '9px', wordBreak: 'break-all', padding: '4px', background: state.comment?state.comment.attention?[1,4].includes(state.comment.attention.id)?'green':state.comment.attention.id==2?'yellow':'red':'':''}}/>,
        comment: <Tooltip classes={{ tooltip: classes.noMaxWidth }} title={<div dangerouslySetInnerHTML={{ __html: state.comment?state.comment.text?state.comment.text:"":"" }} style={{color: 'black', maxWidth: '100%', background: state.comment?state.comment.attention?[1,4].includes(state.comment.attention.id)?'green':state.comment.attention.id==2?'yellow':'red':'':''}}/>}>
                    <div dangerouslySetInnerHTML={{ __html: state.comment?state.comment.text?state.comment.text.slice(0,150)+"...":"":"" }} style={{color: 'black', maxWidth: '250px', overflowWrap: 'break-word', background: state.comment?state.comment.attention?[1,4].includes(state.comment.attention.id)?'green':state.comment.attention.id==2?'yellow':'red':'':''}}></div>
                </Tooltip>,
        'num_country': <EditableINP input={<BuildText json_name='num_country' label="Countries" getError={checkError} onChange={setInput} init_value={state['num_country']}/>} show={state.num_country} pushUpdate={handleSubmitInput}/>,
        
        tags: <EditableINP input={<BuildMultiSelectCreateable json_name="tags" label="Tags" init_value={state.tags} getError={checkError} list={lists.tags} onChange={handleMultiCreateableChange}/>} show={state.tags?state.tags.map(a=>a.label).join(', '):"blank"} pushUpdate={handleSubmitInput}/>,
        logs_url: <EditableINP input={<BuildText json_name='logs_url' label="LOGS" getError={checkError} helper="" onChange={setInput} init_value={state['logs_url']}/>} show={<span>edit {(state.url && state.logs_url!='None' && state.logs_url!='')?<a href={state.logs_url} target="_blank">link</a>:''}</span>} pushUpdate={handleSubmitInput}/>,
        folder_url: <EditableINP input={<BuildText json_name='folder_url' label="Materials Folder" getError={checkError} helper="" onChange={setInput} init_value={state['folder_url']}/>} show={<span>edit {(state.folder_url && state.folder_url!='None' && state.folder_url!='')?<a href={state.folder_url} target="_blank">link</a>:''}</span>} pushUpdate={handleSubmitInput}/>,
        
    }

    var billings_for_chart = [];
    for(var el in state.billings){
        billings_for_chart.push(JSON.parse(state.billings[el]));
    }


    const onStart = () => {
        console.log('whatever')
      };
    
    const onStop = () => {
        console.log('whatever')
      };

    const dragHandlers = {onStart: onStart, onStop: onStop};

    const handleActionsContext = (event) => {
        event.preventDefault();
        setContextPos({
            mouseX: event.clientX - 2,
            mouseY: event.clientY - 4,
        })
        setStil({
            background: '#666666',
            color: '#ffffff',
        });
    }

    const handleCloseContext = () => {
        setContextPos({
            mouseX: null,
            mouseY: null,
        })
        setStil({
            background: '#ffffff',
            color: '#333333',
        });
    }

    return (
        
        <React.Fragment>

            <Menu
                keepMounted
                open={contextpos.mouseY !== null}
                onClose={handleCloseContext}
                anchorReference="anchorPosition"
                anchorPosition={
                    contextpos.mouseY !== null && contextpos.mouseX !== null
                        ? { top: contextpos.mouseY, left: contextpos.mouseX }
                        : undefined
                }
            >
                {cells['controls']}
            </Menu>


                <TableRow onContextMenu={handleActionsContext} style={stil} key={state.id}>
                {cols_order.map((x, ind) => {
                    return(
                        <React.Fragment>
                    <TableCell>{cells[x]}</TableCell>
                    
                    <div className={classes.box_noDrag}></div>
                  
                    </React.Fragment>
                    )
                    
                    })}
                </TableRow>
            {state.linked_projects_jsons && state.expand &&
                state.linked_projects_jsons.map((y, i) =>
                    <LightProjectView 
                    init_state={y} 
                    cols_order={cols_order} 
                    lists={lists} 
                    user={user} 
                    onDelete={onDelete} 
                    onEdit={onEdit} 
                    onAddTask={onAddTask}
                    onSuccessfullUpdate={onSuccessfullUpdate}
                    key={y.id} />
                )
            }

        </React.Fragment>
    )

})


export { LightProjectView }